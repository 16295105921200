.logo{
  height: 80px;
  width: auto;
}

.step {
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  line-height: 40px;
}

.timer {
  font-size: 3rem;
}